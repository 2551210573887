body {
    color: #4F4637;
}

h1, h2, h3, h4 {
    color: $primary;
    font-weight: 300;
    font-family: 'Poppins', sans-serif !important;
}

h1, h2, h3, h4, p {
    margin-bottom: 1.5rem;
}

a {
    text-decoration: none;
}