$body-font-family: 'Lato', sans-serif !important;

$primary: #7B6045;
$secondary: #8D857E;
$light: #F9F5F0;

$border-radius: 0px;
$btn-padding-x: 1.25rem;

$navbar-nav-link-padding-x: 1.5rem;
$btn-padding-x: 2rem;